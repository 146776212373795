@import '@/components/mixins.scss';

label {
	margin: 0 !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

:global(div.ant-form-item-label) {
    height: 30px;
}